import { Stage } from "./types";

export const getEnvVarString = (varName: string) => {
  const stringValue = import.meta.env[varName];
  if (!stringValue) {
    throw new Error(`Environment variable "${varName}" is not defined`);
  }
  return stringValue;
};

export const getEnvVarInt = (varName: string) => {
  const stringValue = getEnvVarString(varName);
  const intValue = parseInt(stringValue, 10);
  if (isNaN(intValue)) {
    throw new Error(`Value of environment variable "${varName}" is not a valid integer`);
  }
  return intValue;
};

export const ENV = {
  stage: getEnvVarString("VITE_STAGE").toLowerCase() as Stage,
  bankeinsApiUrl: getEnvVarString("VITE_BANKEINS_API_URL"),
  partnerApiUrl: getEnvVarString("VITE_PARTNER_API_URL"),
  apiKey: getEnvVarString("VITE_API_KEY"),
  conclusionBaseUrl: getEnvVarString("VITE_CONCLUSION_BASE_URL"),
};

export const isDevelopment = () => import.meta.env.DEV;

export const isTestStage = () => import.meta.env.DEV || ["test", "int", "kons"].includes(ENV.stage);

export const isTrackingEnabled = () => import.meta.env.PROD && ["kons", "prod"].includes(ENV.stage);

export const isChatEnabled = () => ["kons", "prod"].includes(ENV.stage);
